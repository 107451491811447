import * as classes from "./connection-key-modal.module.scss";

const ConnectionKeyModal = ({ connectionKey }) => {
  return (
    <div className={classes.root}>
      {!!connectionKey ? (
        <>
          <span className={classes.message}>Controller connection code:</span>
          <span className={classes.key}>
            {connectionKey.split("").map((char, i) => {
              return (
                <span key={i} className={classes.char}>
                  {char}
                </span>
              );
            })}
          </span>
        </>
      ) : (
        <span className={classes.message}>Connecting to server...</span>
      )}
    </div>
  );
};
export default ConnectionKeyModal;
