import * as classes from "./choose-controller.module.scss";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ControllerFullscreenButton from "../components/ControllerFullscreenButton/ControllerFullscreenButton";
import { CHARACTER_ORDER } from "../config/config";
import { AppContext } from "../contexts/AppContext";
import { ActionType, ResourceType } from "../contexts/types";
import { useSwipeable } from "react-swipeable";

const ChooseController = () => {
  const { state, dispatch } = useContext(AppContext);
  const { characterType } = state;
  const [currentIndex, setCurrentIndex] = useState(
    CHARACTER_ORDER.indexOf(characterType)
  );

  useEffect(() => {
    setCurrentIndex(CHARACTER_ORDER.indexOf(characterType));
  }, [characterType]);

  const increment = useCallback(
    (inc: -1 | 1) => {
      let newIndex = currentIndex + inc;
      if (newIndex < 0) newIndex = CHARACTER_ORDER.length - 1;
      if (newIndex === CHARACTER_ORDER.length) newIndex = 0;
      setCurrentIndex(newIndex);
      dispatch({
        type: ActionType.PickCharacterType,
        value: {
          type: CHARACTER_ORDER[newIndex],
          confirm: false,
        },
      });
    },
    [currentIndex]
  );

  const handlers = useSwipeable({
    onSwipedLeft: () => increment(1),
    onSwipedRight: () => increment(-1),
  });

  return (
    <div {...handlers} className={classes.root}>
      <ControllerFullscreenButton
        emphasis="none"
        onClick={() => {
          increment(1);
        }}
        label="Previous"
      />
      <ControllerFullscreenButton
        onClick={() => {
          dispatch({
            type: ActionType.PickCharacterType,
            value: {
              type: CHARACTER_ORDER[currentIndex],
              confirm: true,
            },
          });
        }}
        label="Proceed"
        message={`Play as ${ResourceType[CHARACTER_ORDER[currentIndex]]}`}
      />
      <ControllerFullscreenButton
        emphasis="none"
        onClick={() => {
          increment(-1);
        }}
        label="Next"
      />
    </div>
  );
};

export default ChooseController;
