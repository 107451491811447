import * as classes from "./main-reset-warning.module.scss";
import ScreenCaption from "../ScreenCaption/ScreenCaption";
import UIHintLabel from "../UIHintLabel/UIHintLabel";

const MainResetWarning = () => {
  return (
    <div className={classes.root}>
      <UIHintLabel
        className={classes.hint}
        headerContent="Use the tablet to interact"
        messageContent={
          <span>
            Are you still there? Tap anywhere to{" "}
            <span>restore this session.</span>
          </span>
        }
      />
      <ScreenCaption message="The game will automatically restart due to inactivity" />{" "}
    </div>
  );
};

export default MainResetWarning;
