const _temp0 = require("url:../bird-15/scene.glb");
const _temp1 = require("url:../fungi-1/scene.glb");
const _temp2 = require("url:../bird-5/scene.glb");
const _temp3 = require("url:../bird-1/scene.glb");
const _temp4 = require("url:../fungi-15/scene.glb");
const _temp5 = require("url:../fungi-5/scene.glb");
const _temp6 = require("url:../glacier-1/scene.glb");
const _temp7 = require("url:../glacier-15/scene.glb");
const _temp8 = require("url:../glacier-5/scene.glb");
const _temp9 = require("url:../human-1/scene.glb");
const _temp10 = require("url:../human-15/scene.glb");
const _temp11 = require("url:../human-5/scene.glb");
const _temp12 = require("url:../mountain-1/scene.glb");
const _temp13 = require("url:../mountain-15/scene.glb");
const _temp14 = require("url:../mountain-5/scene.glb");
const _temp15 = require("url:../river-1/scene.glb");
const _temp16 = require("url:../river-15/scene.glb");
const _temp17 = require("url:../river-5/scene.glb");
const _temp18 = require("url:../tree-1/scene.glb");
const _temp19 = require("url:../tree-15/scene.glb");
const _temp20 = require("url:../tree-5/scene.glb");
module.exports = {
  "bird-15": _temp0,
  "fungi-1": _temp1,
  "bird-5": _temp2,
  "bird-1": _temp3,
  "fungi-15": _temp4,
  "fungi-5": _temp5,
  "glacier-1": _temp6,
  "glacier-15": _temp7,
  "glacier-5": _temp8,
  "human-1": _temp9,
  "human-15": _temp10,
  "human-5": _temp11,
  "mountain-1": _temp12,
  "mountain-15": _temp13,
  "mountain-5": _temp14,
  "river-1": _temp15,
  "river-15": _temp16,
  "river-5": _temp17,
  "tree-1": _temp18,
  "tree-15": _temp19,
  "tree-5": _temp20
}